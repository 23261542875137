<template>
  <div class="help">
    <div class="title">
      <ul class="title_cont">
        <li>
          <i class="iconfont icon-back" @click="backlist"></i>
          <span>系统指南</span>
        </li>
        <li>
          <i class="iconfont icon-menu-s"></i>
        </li>
      </ul>
    </div>
    <h1>“律呗”产品使用手册</h1>
    <div class="helpcontent">
      <ul class="catalog">
        <li v-for="(item, index) in cataloglist" :key="index">
          {{ item.name }}
        </li>
      </ul>
      <div class="introduce">
        &nbsp;&nbsp;由资深法律专家，人工智能、大数据专家，历时四年，投入1200多万元倾力打造的律师案件管理律所管理系统，正式上线。系统在主要功能上取得多项革命性突破，颠覆现有律师办案、律所管理工具。功能强大，操作简单，极大提高律师工作效率和办案质量。系统申请发明专利8个、著作权23个，主要功能介绍如下：
      </div>
      <!-- 第一部分 -->
      <div class="item">
        <div class="ttle">一、人工智能诉讼策略分析报告</div>
        <div class="text">
          &nbsp;&nbsp;在分析中录入简要案情或者关键词（也可拍摄起诉书、答辩状等法律文书），案情分析机器人会检索出法律法规、相似判例、相关的争议焦点、法院观点、诉讼时间等等要素。一键下载、收藏或者生成报告。交给客户彰显工作成绩，提高成案率和收费标准。
        </div>
        <div class="imgs">
          <img src="./img/jiqien.png" />
          <img src="./img/jiqien1.png" />
        </div>
      </div>
      <!-- 第二部分 -->
      <div class="item">
        <div class="ttle">二、人工智能辅助撰写法律文书</div>
        <div class="text">&nbsp;&nbsp;1、系统中筛选的法律法规、争议焦点、法院观点、证据质证等，筛选编辑制作检索报告、代理思路，把代理词的撰写变成文章摘编。</div>
        <div class="imgs">
          <img src="./img/1.png" />
          <img src="./img/1a.png" />
        </div>
        <div class="text">&nbsp;&nbsp;2、撰写代理词等法律文书时，系统会自动推送相关的法规法条，方便准确引用。</div>
        <div class="imgs">
          <img src="./img/2.png" />
          <img src="./img/2a.png" />
        </div>
        <div class="text">
          &nbsp;&nbsp;3、制作文书时，随时可以打开案情分析机器人进行检索，查看相关知识、当事人历史案例、律师法官历史案例、电子档案、收藏的文本等资料，方便制作文书时引用（只支持网页版）。
        </div>
        <div class="imgs">
          <img src="./img/3.png" style="width: 100%" />
        </div>
        <div class="text">
          &nbsp;&nbsp;4、书写完起诉书，能够自动生成代理公函、法定代表人身份证明书、授权委托书、接待笔录、诉讼风险告知书、律师服务质量监督卡、民事代理合同等文书。这些文书可以打包下载打印。一旦结案，办案小结、卷宗封皮、卷宗目录等订卷文书自动生成
        </div>
        <div class="imgs">
          <img src="./img/4.png" />
          <img src="./img/4a.png" />
        </div>
      </div>
      <!-- 第三部分 -->
      <div class="item">
        <div class="ttle">
          三、人工智能法律检索
          <br />
          <br />
          &nbsp;&nbsp;人工智能法律检索体现在三个地方，都能选中下载、收藏、编辑。
        </div>
        <div class="text">&nbsp;&nbsp;书写完起诉书，在资讯中，自动显示相似判例、相关法律法规、相关裁判规则，省略了检索步骤，客观、全面、便捷。</div>
        <div class="imgs">
          <img src="./img/th1a.jpg" />
          <img src="./img/th1ab.jpg" />
        </div>
        <div class="imgs">
          <img src="./img/th1ac.jpg" />
          <img src="./img/th1ar.jpg" />
        </div>
        <div class="text">&nbsp;&nbsp;2、书写了新的起诉书后，资讯中的结果变化了，案件管理详细页面的案情分析机器人会随时提供该案件的检索结果。</div>
        <div class="imgs">
          <img src="./img/th2a.jpg" />
          <img src="./img/th2b.jpg" />
        </div>
        <div class="text">&nbsp;&nbsp;3、打开一篇判例。点击相似判例自动显示本院、上级法院、最高法院的相似判例。</div>
        <div class="imgs">
          <img src="./img/th3a.jpg" />
          <img src="./img/th3b.png" />
        </div>
        <div class="text">&nbsp;&nbsp;点击相关法律法规。自动显示该类案件，涉及到的法律法规。</div>
        <div class="imgs">
          <img src="./img/thfa1.jpg" />
          <img src="./img/thfa2.png" />
        </div>
        <div class="text">&nbsp;&nbsp;点击相关判例。自动显示。该案件当事人的历史案例。</div>
        <div class="imgs">
          <img src="./img/thpan1.jpg" />
          <img src="./img/thpan2.png" />
        </div>
        <div class="text">&nbsp;&nbsp;4、输入简要案情（或拍摄起诉书），案情分析机器人自动检索该类案件的法律法规、相似案例等</div>
        <div class="imgs">
          <img src="./img/th4.jpg" />
          <img src="./img/th43.jpg" />
        </div>
      </div>
      <!-- 第四部分 -->
      <div class="item">
        <div class="ttle">四、可视化案件管理</div>
        <div class="text">&nbsp;&nbsp;结合甘特图实现案件管理可视化，同时给予人工智能、大数据支持，诉讼流程一目了然，方便操作。</div>
        <div class="imgs">
          <img src="./img/fo1.jpg" />
          <img src="./img/fo1a.png" />
        </div>
      </div>
      <!-- 第五部分 -->
      <div class="item">
        <div class="ttle">五、经验传承与办案规范化</div>
        <div class="text">&nbsp;&nbsp;在办理案件的过程中，把经验，做法记录在日程中，选择经验复用，既可以传承经验，也可以规范办案标准。</div>
        <div class="imgs">
          <img src="./img/fi1.png" />
          <img src="./img/fi1a.png" />
        </div>
      </div>
      <!-- 第六部分 -->
      <div class="item">
        <div class="ttle">六、协同办案</div>
        <div class="text">&nbsp;&nbsp;在可视化案件管理的各个节点都可以发起协同办案，随时聊天，安排工作，交流讨论，协同办案人自动创建聊天群组，方便交流讨论。</div>
        <div class="imgs">
          <img src="./img/si1.png" />
          <img src="./img/si1a.jpg" />
        </div>
      </div>
      <!-- 第七部分 -->
      <div class="item">
        <div class="ttle">七、一键归档与档案查询</div>
        <div class="text">&nbsp;&nbsp;案件结案，一键生成电子档案，生成的电子档案，可以全所律师共享，在撰写文书，案件管理模块中，能够很方便的查看。</div>
        <div class="imgs">
          <img src="./img/se1.jpg" />
          <img src="./img/se1a.png" />
        </div>
      </div>
      <!-- 第八部分 -->
      <div class="item">
        <div class="ttle">八、可视化法律检索</div>
        <div class="text">&nbsp;&nbsp;输入的关键词，自动推送近义词等相关词语，方便替换，精准检索。</div>
        <div class="imgs">
          <img src="./img/ele.png" style="width: 100%" />
        </div>
        <div class="text">&nbsp;&nbsp;输入两个以上的关键词，拖动其中一个关键词，到另外的关键词上面，组合成同句、同段、或者、不包含的检索条件，方便更加精准的检索。</div>
        <div class="imgs">
          <img src="./img/eleguan.png" style="width: 100%" />
        </div>
      </div>
      <!-- 第九部分 -->
      <div class="item">
        <div class="ttle">九、人工智能律师专用日历</div>
        <div class="text">
          &nbsp;&nbsp;日历与案件的数据打通。引入开庭、申请鉴定、补充证据等律师经常的重要日程。并与案件的进程相关联，方便创建日程。创建的日程与案件管理的节点数据相通。
          还可以很方便的创建待办事项、记录笔记，整理思路。
        </div>
        <div class="imgs">
          <img src="./img/ni1.jpg" />
          <img src="./img/ni1a.png" />
        </div>
        <div class="text">&nbsp;&nbsp;标签</div>
        <div class="imgs">
          <img src="./img/title1.png" />
          <img src="./img/title1a.jpg" />
        </div>
        <div class="text">&nbsp;&nbsp;管理案件</div>
        <div class="imgs">
          <img src="./img/case1.jpg" />
          <img src="./img/case1a.png" />
        </div>
        <div class="text">&nbsp;&nbsp;笔记插入到文书里</div>
        <div class="imgs">
          <img src="./img/biji1.png" />
          <img src="./img/biji1a.jpg" />
        </div>
        <div class="text">&nbsp;&nbsp;协同办案</div>
        <div class="imgs">
          <img src="./img/xietong1.jpg" />
          <img src="./img/xietong1a.png" />
        </div>
      </div>
      <!-- 第十部分 -->
      <div class="item">
        <div class="ttle">十、其他功能</div>
        <div class="text">
          &nbsp;&nbsp;1、拍照识别文字、分析案情、新建项目
          <br />
          通过拍照，可以提取文字，也可以直接进行法律检索、进行案情分析，新建项目。对传票进行拍照，可以直接创建日程。也可以拍照网上立案。
        </div>
        <div class="imgs">
          <img src="./img/shi1.jpg" />
          <img src="./img/shi1a.jpg" />
        </div>
        <div class="text">
          &nbsp;&nbsp;系统还有律所管理、客户管理、网盘等模块。为提高客户体验。庞大的系统各个模块之间的数据相通，比如：分析中录入的案情可以直接录入到创建的起诉书中。写完了起诉书，可以一键进行案情分析。也可以一键新建项目。不论是案情分析机器人还是人工检索的结果，都可以一键下载、编辑、打印。方便简洁的设计随处可见。
        </div>
        <div class="img">
          <img src="./img/downlode.png" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      cataloglist: [
        {
          name: '目录',
        },
        {
          name: '一、人工智能诉讼策略分析报告',
        },
        {
          name: '二、人工智能辅助撰写法律文书',
        },
        {
          name: '三、人工智能法律检索',
        },
        {
          name: '四、可视化案件管理',
        },
        {
          name: '五、经验传承与办案规范化',
        },
        {
          name: '六、协同办案',
        },
        {
          name: '七、一键归档与档案查询',
        },
        {
          name: '八、 可视化法律检索',
        },
        {
          name: '九、人工智能律师专用日历',
        },
        {
          name: '十、其他功能',
        },
      ],
    }
  },
  methods: {
    backlist() {
      const ua = navigator.userAgent
      if (ua === 'fb-Android-Webview') {
        // eslint-disable-next-line
        AND2JS.back()
      } else if (ua === 'fb-flutter-Webview') {
        // eslint-disable-next-line
        try {
          window.back.postMessage({})
        } catch (error) {
          console.log('back.postMessage', error)
        }
      } else {
        window.webkit.messageHandlers.back.postMessage({})
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
*
  margin 0px
  padding 0px
  list-style none
  .help
    width 100%
    height 100%
    .title
      width 100%
      height 40px
      line-height 40px
      border-bottom 1px solid #CCCCCC
      position fixed
      top 0px
      left 0px
      z-index 99
      background #ffffff
      .title_cont
        width 95%
        height 100%
        margin 0px auto
        display flex
        justify-content space-between
        li:nth-child(1)
          span
            color #333333
            font-size 15px
            font-weight 400
            margin-left 10px
    h1
      color #000000
      font-size 16px
      font-family PingFangSC
      font-weight 400
      text-align center
      margin-top 50px
    .helpcontent
      width 100%
      padding 20px
      .catalog
        color #000000
        font-size 15px
        color rgba(0, 0, 0, 1)
        font-family PingFangSC
        font-weight 400
        li
          line-height 30px
      .introduce
        color #333
        font-size 15px
        color rgba(0, 0, 0, 1)
        font-family PingFangSC
        font-weight 400
        line-height 25px
        margin-top 10px
      .item
        width 100%
        overflow hidden
        font-size 16px
        margin-top 20px
        .ttle
          font-weight 600
          margin-bottom 15px
        .text
          line-height 25px
          margin-bottom 15px
        .imgs
          width 100%
          overflow hidden
          display flex
          justify-content space-between
          padding 5px
          box-sizing border-box
          margin-bottom 15px
          img
            width 48%
            max-height 300px
            border 1px solid #CCCCCC
</style>

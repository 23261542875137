var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "help" }, [
    _c("div", { staticClass: "title" }, [
      _c("ul", { staticClass: "title_cont" }, [
        _c("li", [
          _c("i", {
            staticClass: "iconfont icon-back",
            on: { click: _vm.backlist },
          }),
          _c("span", [_vm._v("系统指南")]),
        ]),
        _vm._m(0),
      ]),
    ]),
    _c("h1", [_vm._v("“律呗”产品使用手册")]),
    _c("div", { staticClass: "helpcontent" }, [
      _c(
        "ul",
        { staticClass: "catalog" },
        _vm._l(_vm.cataloglist, function (item, index) {
          return _c("li", { key: index }, [
            _vm._v(" " + _vm._s(item.name) + " "),
          ])
        }),
        0
      ),
      _c("div", { staticClass: "introduce" }, [
        _vm._v(
          "   由资深法律专家，人工智能、大数据专家，历时四年，投入1200多万元倾力打造的律师案件管理律所管理系统，正式上线。系统在主要功能上取得多项革命性突破，颠覆现有律师办案、律所管理工具。功能强大，操作简单，极大提高律师工作效率和办案质量。系统申请发明专利8个、著作权23个，主要功能介绍如下： "
        ),
      ]),
      _vm._m(1),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _vm._m(5),
      _vm._m(6),
      _vm._m(7),
      _vm._m(8),
      _vm._m(9),
      _vm._m(10),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", [_c("i", { staticClass: "iconfont icon-menu-s" })])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [
        _vm._v("一、人工智能诉讼策略分析报告"),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "   在分析中录入简要案情或者关键词（也可拍摄起诉书、答辩状等法律文书），案情分析机器人会检索出法律法规、相似判例、相关的争议焦点、法院观点、诉讼时间等等要素。一键下载、收藏或者生成报告。交给客户彰显工作成绩，提高成案率和收费标准。 "
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/jiqien.png") } }),
        _c("img", { attrs: { src: require("./img/jiqien1.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [
        _vm._v("二、人工智能辅助撰写法律文书"),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  1、系统中筛选的法律法规、争议焦点、法院观点、证据质证等，筛选编辑制作检索报告、代理思路，把代理词的撰写变成文章摘编。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/1.png") } }),
        _c("img", { attrs: { src: require("./img/1a.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  2、撰写代理词等法律文书时，系统会自动推送相关的法规法条，方便准确引用。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/2.png") } }),
        _c("img", { attrs: { src: require("./img/2a.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "   3、制作文书时，随时可以打开案情分析机器人进行检索，查看相关知识、当事人历史案例、律师法官历史案例、电子档案、收藏的文本等资料，方便制作文书时引用（只支持网页版）。 "
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("./img/3.png") },
        }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "   4、书写完起诉书，能够自动生成代理公函、法定代表人身份证明书、授权委托书、接待笔录、诉讼风险告知书、律师服务质量监督卡、民事代理合同等文书。这些文书可以打包下载打印。一旦结案，办案小结、卷宗封皮、卷宗目录等订卷文书自动生成 "
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/4.png") } }),
        _c("img", { attrs: { src: require("./img/4a.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [
        _vm._v(" 三、人工智能法律检索 "),
        _c("br"),
        _c("br"),
        _vm._v(
          "   人工智能法律检索体现在三个地方，都能选中下载、收藏、编辑。 "
        ),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  书写完起诉书，在资讯中，自动显示相似判例、相关法律法规、相关裁判规则，省略了检索步骤，客观、全面、便捷。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/th1a.jpg") } }),
        _c("img", { attrs: { src: require("./img/th1ab.jpg") } }),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/th1ac.jpg") } }),
        _c("img", { attrs: { src: require("./img/th1ar.jpg") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  2、书写了新的起诉书后，资讯中的结果变化了，案件管理详细页面的案情分析机器人会随时提供该案件的检索结果。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/th2a.jpg") } }),
        _c("img", { attrs: { src: require("./img/th2b.jpg") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  3、打开一篇判例。点击相似判例自动显示本院、上级法院、最高法院的相似判例。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/th3a.jpg") } }),
        _c("img", { attrs: { src: require("./img/th3b.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("  点击相关法律法规。自动显示该类案件，涉及到的法律法规。"),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/thfa1.jpg") } }),
        _c("img", { attrs: { src: require("./img/thfa2.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v("  点击相关判例。自动显示。该案件当事人的历史案例。"),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/thpan1.jpg") } }),
        _c("img", { attrs: { src: require("./img/thpan2.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  4、输入简要案情（或拍摄起诉书），案情分析机器人自动检索该类案件的法律法规、相似案例等"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/th4.jpg") } }),
        _c("img", { attrs: { src: require("./img/th43.jpg") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("四、可视化案件管理")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  结合甘特图实现案件管理可视化，同时给予人工智能、大数据支持，诉讼流程一目了然，方便操作。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/fo1.jpg") } }),
        _c("img", { attrs: { src: require("./img/fo1a.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("五、经验传承与办案规范化")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  在办理案件的过程中，把经验，做法记录在日程中，选择经验复用，既可以传承经验，也可以规范办案标准。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/fi1.png") } }),
        _c("img", { attrs: { src: require("./img/fi1a.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("六、协同办案")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  在可视化案件管理的各个节点都可以发起协同办案，随时聊天，安排工作，交流讨论，协同办案人自动创建聊天群组，方便交流讨论。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/si1.png") } }),
        _c("img", { attrs: { src: require("./img/si1a.jpg") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("七、一键归档与档案查询")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  案件结案，一键生成电子档案，生成的电子档案，可以全所律师共享，在撰写文书，案件管理模块中，能够很方便的查看。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/se1.jpg") } }),
        _c("img", { attrs: { src: require("./img/se1a.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("八、可视化法律检索")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  输入的关键词，自动推送近义词等相关词语，方便替换，精准检索。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("./img/ele.png") },
        }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "  输入两个以上的关键词，拖动其中一个关键词，到另外的关键词上面，组合成同句、同段、或者、不包含的检索条件，方便更加精准的检索。"
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", {
          staticStyle: { width: "100%" },
          attrs: { src: require("./img/eleguan.png") },
        }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("九、人工智能律师专用日历")]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "   日历与案件的数据打通。引入开庭、申请鉴定、补充证据等律师经常的重要日程。并与案件的进程相关联，方便创建日程。创建的日程与案件管理的节点数据相通。 还可以很方便的创建待办事项、记录笔记，整理思路。 "
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/ni1.jpg") } }),
        _c("img", { attrs: { src: require("./img/ni1a.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [_vm._v("  标签")]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/title1.png") } }),
        _c("img", { attrs: { src: require("./img/title1a.jpg") } }),
      ]),
      _c("div", { staticClass: "text" }, [_vm._v("  管理案件")]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/case1.jpg") } }),
        _c("img", { attrs: { src: require("./img/case1a.png") } }),
      ]),
      _c("div", { staticClass: "text" }, [_vm._v("  笔记插入到文书里")]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/biji1.png") } }),
        _c("img", { attrs: { src: require("./img/biji1a.jpg") } }),
      ]),
      _c("div", { staticClass: "text" }, [_vm._v("  协同办案")]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/xietong1.jpg") } }),
        _c("img", { attrs: { src: require("./img/xietong1a.png") } }),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "item" }, [
      _c("div", { staticClass: "ttle" }, [_vm._v("十、其他功能")]),
      _c("div", { staticClass: "text" }, [
        _vm._v("   1、拍照识别文字、分析案情、新建项目 "),
        _c("br"),
        _vm._v(
          " 通过拍照，可以提取文字，也可以直接进行法律检索、进行案情分析，新建项目。对传票进行拍照，可以直接创建日程。也可以拍照网上立案。 "
        ),
      ]),
      _c("div", { staticClass: "imgs" }, [
        _c("img", { attrs: { src: require("./img/shi1.jpg") } }),
        _c("img", { attrs: { src: require("./img/shi1a.jpg") } }),
      ]),
      _c("div", { staticClass: "text" }, [
        _vm._v(
          "   系统还有律所管理、客户管理、网盘等模块。为提高客户体验。庞大的系统各个模块之间的数据相通，比如：分析中录入的案情可以直接录入到创建的起诉书中。写完了起诉书，可以一键进行案情分析。也可以一键新建项目。不论是案情分析机器人还是人工检索的结果，都可以一键下载、编辑、打印。方便简洁的设计随处可见。 "
        ),
      ]),
      _c("div", { staticClass: "img" }, [
        _c("img", { attrs: { src: require("./img/downlode.png") } }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }